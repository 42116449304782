
















































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
@Component({
  name: 'layoutSidebar',
  components: {},
})
export default class Sidebar extends VueBase {
  @Prop() menu: any
  private favicon = '/upload/assets/img/favicon.ico?v=' + String(Math.random())
  private logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  private logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  menuIcons: any = {
    iconxiangmu1: require('../../assets/img/icon_project.png'),
    iconxiangmu1_cur: require('../../assets/img/icon_project_cur.png'),

    iconzhinan: require('../../assets/img/icon_project.png'),
    iconzhinan_cur: require('../../assets/img/icon_project.png'),

    iconjicheng: require('../../assets/img/icon_jicheng.png'),
    iconjicheng_cur: require('../../assets/img/icon_jicheng_cur.png'),

    iconchaxun1: require('../../assets/img/icon_data.png'),
    iconchaxun1_cur: require('../../assets/img/icon_data_cur.png'),

    iconbaogao: require('../../assets/img/icon_report.png'),
    iconbaogao_cur: require('../../assets/img/icon_report_cur.png'),

    iconcelve: require('../../assets/img/icon_celue.png'),
    iconcelve_cur: require('../../assets/img/icon_celue_cur.png'),

    icondashboard: require('../../assets/img/icon_home.png'),
    icondashboard_cur: require('../../assets/img/icon_home_cur.png'),

    authority: require('../../assets/img/icon_sys.png'),
    authority_cur: require('../../assets/img/icon_sys_cur.png'),
  }
  menuHidden: boolean = true
  // isMenuChoose: boolean = false
  subMenus: any = []
  // isCollapse: boolean = false
  curMenu: string = ''
  curPMenu: string = ''
  hoverCurMenu: string = ''
  pNenuItem: any = {}
  created() {}
  handleDocumentClick(e: Event) {
    this.subMenus = []
    this.menuHidden = true
    this.hoverCurMenu = ''
  }
  mounted() {
    document.removeEventListener('click', this.handleDocumentClick)
    document.addEventListener('click', this.handleDocumentClick, false)
  }
  mouseoutMenuHandle() {
    if (this.hoverCurMenu !== this.curPMenu) {
      this.hoverCurMenu = ''
    }
  }

  subMenuMouseover() {
    this.menuHidden = false
  }

  clickMenuHandle1() {
    this.menuHidden = true
  }
  clickMenuHandle(e: any, menuObj: any, isClick: boolean) {
    e.stopPropagation()
    e.preventDefault()
    if (!isClick) {
      this.hoverCurMenu = menuObj.name
    }
    if (
      menuObj.children.length > 0 &&
      !menuObj.hidden &&
      this.rightsRouters.includes(menuObj.name)
    ) {
      // if (menuObj.children.length > 0 && !menuObj.hidden) {
      for (let i = 0; i < this.menu.length; i++) {
        const item: any = this.menu[i]
        if (menuObj.name == item.name) {
          this.pNenuItem = menuObj
          this.subMenus = item.children || []
          this.curPMenu = menuObj.name
          this.menuHidden = false
          break
        }
      }
    } else {
      this.menuHidden = true
      this.subMenus = []
      this.curMenu = ''
      this.curPMenu = ''
      this.pNenuItem = ''
      this.hoverCurMenu = ''
      if (this.rightsRouters.includes(menuObj.name)) {
        this.curMenu = menuObj.name
        if (isClick) {
          this.$router.push({ name: menuObj.name })
        }
        return
      }
      // this.curMenu = menuObj.name
      // if (isClick) {
      //   this.$router.push({ name: menuObj.name })
      // }
      // return
    }
  }
  handleClick(item: any) {
    this.menuHidden = true
    this.subMenus = []
    const name = this.$route.name
    if (name == 'settingSensitive' && item.name == 'hookRule') {
       return
    }
    this.$router.push({ name: item.name })
  }
  handleOpen(key: any, keyPath: any) {
    console.log(key, keyPath)
  }
  handleClose(key: any, keyPath: any) {
    console.log(key, keyPath)
  }
  getDefaultActive() {
    // this.subMenus = []
    // this.pNenuItem = ''
    // this.hoverCurMenu = ''
    // this.curMenu = ''
    // this.curPMenu = ''

    let arr = this.menu
      .map((item: any) => {
        if (item.hidden) {
          // this.curMenu = item.name
          // this.curPMenu = item.pName || item.name
          return item
        }
        return [...item.children]
      })
      .flat()
    const name = this.$route.name
    const haveMenu = arr.find((ele: any) => ele.name === name)
    if (true) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (item.name === name) {
          this.curMenu = item.name
          this.curPMenu = item.pName || item.name
          return item.name
        }
        if (item.children && item.children.length > 0) {
          let flag = item.children.find((ele: any) => ele.name === name)
          if (flag) {
            this.curPMenu = item.pName
            this.curMenu = item.name
            return item.name
          }
          if (name == 'settingSensitive') {
            this.curPMenu = item.pName
            this.curMenu = 'hookRule'
            return 'hookRule'
          }
        }
      }
    } else {
      arr = this.menu
        .map((item: any) => {
          return [item]
        })
        .flat()
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (item.name === name) {
          // this.curMenu = item.name
          this.curPMenu = item.pName || item.name
          return item.name
        }
      }
    }
    return ''
  }

  // private parentMenus: Array<any> = []
  @Watch('$route', { immediate: true })
  watchRouteSwitch() {
    this.curMenu = ''
    this.curPMenu = ''
  }

  get parentMenus() {
    const parentMenus: any = []
    this.menu
      .filter((item: any) => !item.hiddenMenu)
      .map((item: any) => {
        if (this.rightsRouters.includes(item.name)) parentMenus.push(item)
      })
    return parentMenus
  }
  get userInfo(): { role: number } {
    return this.$store.getters.userInfo
  }
  get rightsRouters() {
    const routers = this.$store.getters.rightsRouters || []
    // routers.map((item:any) => {
    //   if (item == 'integrationManagement') {
    //     item = 'setting'
    //   }
    //   return item;
    // })
    return routers
  }
}
